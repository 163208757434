<template>
	<div class="h-wrapper h-new-wrapper" :class="{'font-en': lang!=='zh-cn'}">
		<div class="header" id="header">
			<div class="container1 flex-ac">
				<router-link to="/" class="logo flex-ac">
					<img :src="logo" alt="">
				</router-link>
				<el-menu :default-active="$route.path" :router="true" class="el-menu-nav" mode="horizontal">
					<!-- <el-menu-item index="/" @mouseenter.native="toggleDropdown">
						<span>产品</span><span class="el-icon-arrow-down"></span>
					</el-menu-item>
					<el-menu-item index="/">
						<span>工业</span><span class="el-icon-arrow-down"></span>
					</el-menu-item>
					<el-menu-item index="/">
						<span>服务</span><span class="el-icon-arrow-down"></span>
					</el-menu-item> -->
					<el-menu-item v-for="(item,key) in menus" :key="key" :index="item.url" @mouseenter.native="toggleDropdown(item)" @click="clickit(item.id)">
						<span>{{item.name}}</span>
						<span v-if="item.children && item.children.length > 0" class="el-icon-arrow-down"></span>
					</el-menu-item>
				</el-menu>
				<el-dropdown @command="handleCommand">
					<div class="el-dropdown-lang">
						<span class="iconfont icon-yuyan"></span>
						<span>{{lang==='zh-cn'?'中文':'en'}}</span>
						<span class="el-icon-caret-bottom"></span>
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="zh">中文</el-dropdown-item>
						<el-dropdown-item command="en">en</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<!-- <div class="top-search">
					<el-input placeholder="搜索" v-model="searchValue">
						<div class="btn-so" slot="suffix">
							<img src="../assets/image/index/so.png" alt="" />
						</div>
					</el-input>
				</div> -->
				<div class="menu-icon" @click="drawer = true"><i class="iconfont icon-menu"></i></div>
			</div>
		</div>
		<!-- 下拉 -->
		<div class="menu-dropdown-panel" v-show="dropdownActive" :class="{ active: dropdownActive }">
			<div class="container">
				<transition name="fade">
					<div class="dropdown-panel flex" @mouseleave="closeDropdown">
						<div class="flex_bd">
							<el-row :gutter="20">
								<el-col v-for="(it,idx) in drop_menu" :key="idx" :span="it.span" class="{'other-row':it.span==24}">
									<div class="d-label">{{lang=='zh-cn'?it.title:it.title_en}}</div>
									<el-row>
										<el-col :span="it.span==12?24:12" v-for="(item,index) in it.list" :key="index">
											<div class="pr-card" @click="clickProd(item,index)">
												<img :src="item.pic" alt="" />
												<div class="flex_bd">
													<h3>{{lang=='zh-cn'?item.ltitle:item.ltitle_en}}</h3>
													<p>{{lang=='zh-cn'?item.desc:item.desc_en}}</p>
												</div>
											</div>
										</el-col>
									</el-row>
								</el-col>
								<!-- <el-col :span="12">
									<div class="d-label">定制袋</div>
									<el-row>
										<el-col :span="24" v-for="(item,index) in list2" :key="index">
											<div class="pr-card">
												<img :src="item.pic" alt="" />
												<div class="flex_bd">
													<h3>{{item.title}}</h3>
													<p>{{item.desc}}</p>
												</div>
											</div>
										</el-col>
									</el-row>
								</el-col>
								<el-col :span="24" class="other-row">
									<div class="d-label">其他</div>
									<el-row>
										<el-col :span="12" v-for="(item,index) in list3" :key="index">
											<div class="pr-card">
												<img :src="item.pic" alt="" />
												<div class="flex_bd">
													<h3>{{item.title}}</h3>
													<p>{{item.desc}}</p>
												</div>
											</div>
										</el-col>
									</el-row>
								</el-col> -->
							</el-row>
						</div>
						<!-- <div class="dropdown-aside">
							<div class="diy-select">
								<div class="diy-select-photo">
									<img src="../assets/image/index/option-img@1x.png" alt="" />
								</div>
								<div class="diy-grid">
									<div class="diy-title">选项库</div>
									<div class="diy-desc">
										想和你的定制包装做个声明吗？浏览万嘉纸业工厂不断增长的材料库，选择和包装功能，以发现你如何使你的新定制包装像以前一样有影响力。</div>
									<el-link type="primary">
										<span>浏览所有选项</span> <span class="el-icon-right"></span>
									</el-link>
								</div>
							</div>
						</div> -->
					</div>
				</transition>
			</div>
		</div>
		<el-drawer :visible.sync="drawer" :direction="direction" size='100%' append-to-body>
			<router-link to="/" class="logo flex-ac">
				<img src="../assets/image/index/logo.png" alt="">
			</router-link>
			<div class="drawer-panel">
				<el-collapse v-model="activeNames">
					<el-collapse-item  v-for="(item,key) in menus" :key="key" :index="item.url" :name="item.name" >
						<template slot="title">
							<span class="m-nav-title" @click="clickmit(item)">{{item.name}}</span>
						</template>
						<el-row v-if="item.children && item.children.length>0" :gutter="20">
							<!-- <el-col :span="12"> -->
							<el-col v-for="(it,idx) in item.children" :key="idx" :span="it.span" class="{'other-row':it.span==24}">
								<div class="d-label">{{lang=='zh-cn'?it.title:it.title_en}}</div>
								<el-row>
									<el-col :span="it.span==12?24:12" v-for="(ite,ind) in it.list" :key="ind">
										<div class="pr-card">
											<img :src="ite.pic" alt="" />
											<div class="flex_bd">
												<h3>{{lang=='zh-cn'?ite.ltitle:ite.ltitle_en}}</h3>
												<p>{{lang=='zh-cn'?ite.desc:ite.desc_en}}</p>
											</div>
										</div>
									</el-col>
								</el-row>
							</el-col>
							<!-- <el-col :span="12">
								<div class="d-label">定制袋</div>
								<el-row>
									<el-col :span="24" v-for="(item,index) in list2" :key="index">
										<div class="pr-card">
											<img :src="item.pic" alt="" />
											<div class="flex_bd">
												<h3>{{item.title}}</h3>
												<p>{{item.desc}}</p>
											</div>
										</div>
									</el-col>
								</el-row>
							</el-col>
							<el-col :span="24" class="other-row">
								<div class="d-label">其他</div>
								<el-row>
									<el-col :span="12" v-for="(item,index) in list3" :key="index">
										<div class="pr-card">
											<img :src="item.pic" alt="" />
											<div class="flex_bd">
												<h3>{{item.title}}</h3>
												<p>{{item.desc}}</p>
											</div>
										</div>
									</el-col>
								</el-row>
							</el-col> -->
						</el-row>
					</el-collapse-item>
					<!-- <el-collapse-item name="2">
						<template slot="title">
							<span class="m-nav-title">工业</span>
						</template>
					</el-collapse-item>
					<el-collapse-item name="3">
						<template slot="title">
							<span class="m-nav-title">服务</span>
						</template>
					</el-collapse-item> -->
				</el-collapse>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import {mapState,mapMutations} from 'vuex';
export default {
	data() {
		return {
			activeNames: '1',
			dropdownActive: false, // Track dropdown state
			activeIndex: '1',
			topid: '',
			drawer: false,
			alang: '中文',
			direction: 'ltr',
			searchValue: '',
			drop_menu: [],
			// product_menu:[
			// 	{	name: "原纸",
			// 		span: 24,
			// 		list: [{
			// 			title: 'C1S',
			// 			desc: 'C1S',
			// 			pic: require('../assets/productimg/C1S.png')
			// 		}, {
			// 			title: 'C2S',
			// 			desc: 'C2S',
			// 			pic: require('../assets/productimg/C2S.png')
			// 		}, {
			// 			title: 'duplex',
			// 			desc: 'duplex',
			// 			pic: require('../assets/productimg/duplex.png')
			// 		}, {
			// 			title: 'kraft paper',
			// 			desc: 'kraft paper',
			// 			pic: require('../assets/productimg/kraftpaper.png')
			// 		}, 
			// 		{
			// 			title: 'offset paper',
			// 			desc: 'offset paper',
			// 			pic: require('../assets/productimg/offsetpaper.png')
			// 		}, {
			// 			title: 'PET paper',
			// 			desc: 'PET paper',
			// 			pic: require('../assets/productimg/PETpaper.png')
			// 		}, {
			// 			title: 'poly card',
			// 			desc: 'poly card',
			// 			pic: require('../assets/productimg/polycard.png')
			// 		}, {
			// 			title: 'specialty',
			// 			desc: 'specialty',
			// 			pic: require('../assets/productimg/specialty.png')
			// 		}, ],
			// 	}
			// ],
			// product_menu1:[
			// 	{	name: "盒子包装",
			// 		span: 12,
			// 		list: [
			// 			{
			// 				title: '折叠盒',
			// 				desc: '全方位包装。',
			// 				pic: require('../assets/image/index/p1.png')
			// 			}, {
			// 				title: '瓦楞盒',
			// 				desc: '坚固耐用的包装。',
			// 				pic: require('../assets/image/index/p2.png')
			// 			}, {
			// 				title: '手工盒',
			// 				desc: '高级和豪华的包装。',
			// 				pic: require('../assets/image/index/p3.png')
			// 			}, {
			// 				title: '异形盒',
			// 				desc: '有影响力的柜台',
			// 				pic: require('../assets/image/index/p4.png')
			// 			} 
			// 		],
			// 	}
			// ]
		};
	},
	mounted() {},
	computed:{
		...mapState({
			'lang':state=>state.app.currentLang,
			'webcontents':state=>state.app.webcontents,
			'en_id':state=>state.app.en_id
		}),
		logo(){
			if(this.webcontents && this.webcontents.base && this.webcontents.base.logo){
				return this.webcontents.base.logo
			}else{
				return '' //修改公司默认LOGO
			}
		},
		menus(){
			if(this.lang == "zh-cn"){
				var cnmenu = this.webcontents ? this.webcontents.list.filter(item=>item.pagename_zh != null && item.is_activated):[]
				for(var ite of cnmenu){
					ite.name= ite.pagename_zh;					
					if(!ite.autoproduct){
						ite.url= "/"+ite.type;
						ite.children = ite.items.filter(me=>me.item_type==='childmenu')
						if (ite.children && ite.children.length>0){
							for(let cme of ite.children){
								cme.list = JSON.parse(cme.content)
								if (cme.list.length>0){
									// for(let li of cme.list){
									// 	li.url = "/"+ite.type
									// }
									for(let [li, index] of cme.list){
										li.url = "/"+itm.type+"?pid="+index
									}
								}	
							}
						}
						// 
					}
					else{
						ite.url = ''
					}
					// if(ite.name == '原纸供应'){
					// 	ite.children = this.product_menu
					// }
					// if(ite.name == '盒子包装'){
					// 	ite.children = this.product_menu1
					// }
				}
				return cnmenu;
			}				
			else{
				var enmenu = this.webcontents?this.webcontents.list.filter(item=>item.pagename_en != null && item.is_activated):[]
				for(var itm of enmenu){
					itm.name= itm.pagename_en;
					itm.url= "/"+itm.type+"/";
					if(!itm.autoproduct){
						itm.children = itm.items.filter(me=>me.item_type==='childmenu')
						if (itm.children && itm.children.length>0){
							for(let cme of itm.children){
								cme.list = JSON.parse(cme.content)
								if (cme.list.length>0){
									for(let [li, index] of cme.list){
										li.url = "/"+itm.type+"?pid="+index
									}
								}								
							}
						}
					}
					// if(itm.name == 'Paper'){
					// 	itm.children = this.product_menu
					// }
					// if(itm.name == 'Packaging'){
					// 	itm.children = this.product_menu1
					// }
				}
				return enmenu;
			}
		}
	},
	created() {
		// console.log(this.webcontents)
		if(!this.lang){
			let lan = (navigator.language || navigator.browserLanguage).toLowerCase();
			console.log(lan)
			if (lan) {
				this.setLang(lan) ;
			}
		}
		if(!this.webcontents){
			this.getDataFromServer()
		}
		// console.log(JSON.stringify(this.menus))
		// console.log(menus)
	},
	methods: {		
		...mapMutations({
			setLang(commit, lang) {
				commit('app/SET_LANG', lang)
			},
			setCurrent(commit, current) {
				commit('app/SET_CURRENT', current)
			},
			setWebInfo(commit, webcontent) {
				commit('app/SET_WEB', webcontent)
			},
		}),
		getDataFromServer(){
			this.$axios.get("https://jesway.cn/api/website/all_websites?en_id="+this.en_id).then(res=>{
				// this.$axios.get("http://localhost:8999/api/website/all_websites?en_id="+this.en_id).then(res=>{
				this.setWebInfo(res.data)
				// console.log(JSON.stringify(this.webcontents))	
				this.watch_new = !this.watch_new
			})
		},
		toggleDropdown(item) {
			if(item.children && item.children.length > 0){
				this.drop_menu = item.children
				this.dropdownActive = true; // Toggle dropdown state
				this.topid = item.id
			}
			
		},
		closeDropdown() {
			this.dropdownActive = false; // Close dropdown
		},
		handleCommand(command){
			console.log(command)
			if(command === 'en'){
				this.toEn()
			}else{
				this.toZh()
			}
		},
		toZh(){
			let lang = "zh-cn";
			this.alang = '中文';
			this.setLang(lang) ;				
		},
		toEn(){
			let elang = "en-US";
			this.alang = 'en';
			this.setLang(elang) ;				
		},
		clickit(id){
			this.setCurrent(id)
		},
		clickProd(item, pid){
			console.log(JSON.stringify(item))
			this.setCurrent(this.topid)
			if(item.cid){
				this.$router.push({ path: item.url, query:{cid:item.cid}}).catch((e) => {console.log(e)})
			}else{
				this.$router.push({ path: item.url, query:{pid:pid}})
			}
			
			// this.$router.push({ path: item.url})
		},
		clickmit(item){
			console.log(item)
			if(item.url){
				this.$router.push({ path: item.url})
			}
		},
		toLogin(){
			const externalUrl = '';
			window.location.href = externalUrl;
		}
	}
}
</script>
<style>
	.h-wrapper {
		position: relative;
		z-index: 11;
		height: 72px;
	}

	.header {
		position: fixed;
		width: 100%;
		z-index: 999;
		left: 0;
		right: 0;
		top: 0;
		height: 72px;
		transition: .3s all;
		background-color: #ffffff;
	}

	.menu-icon {
		cursor: pointer;
		display: none;
	}

	.h-new-wrapper .menu-icon .iconfont {
		font-size: 20px;
		color: #1AA8F0;
	}

	.container1{
		margin: 0 auto;
		padding-left: 15px;
		padding-right: 15px;
		height: 100%;
	}
	.header>.container {
		height: 100%;
	}

	.logo img {
		width: 150px;
	}

	.header .el-menu-nav {
		margin-left: auto;
	}

	.h-wrapper .el-menu.el-menu--horizontal {
		background-color: transparent;
		border: 0;
	}

	.h-wrapper .el-menu>.el-menu-item:not(.is-disabled):focus,
	.h-wrapper .el-menu>.el-menu-item:not(.is-disabled):hover,
	.h-wrapper .el-menu>.el-submenu .el-submenu__title:hover {
		background-color: transparent;
	}

	.h-new-wrapper  .el-menu>.el-menu-item {
		color: #000000;
		font-size: 16px;
		border: 0 !important;
		height: 40px;
		line-height: 40px;
	}

	.h-wrapper .el-menu>.el-submenu .el-submenu__title {
		color: #000000;
		font-size: 16px;
		border: 0 !important;
	}

	.h-wrapper .el-menu-item,
	.el-submenu__title {
		font-size: 16px;
		border: 0 !important;
	}

	.h-new-wrapper  .el-menu .el-menu-item:not(.is-disabled):focus,
	.h-new-wrapper  .el-menu .el-menu-item:not(.is-disabled):hover {
		outline: 0;
		color: #1AA8F0;
	}

	.h-new-wrapper  .el-menu>.el-menu-item.is-active {
		color: #1AA8F0;
	}

	.h-new-wrapper  .el-menu>.el-menu-item.is-active::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 20px;
		right: 20px;
		height: 3px;
		background: #1AA8F0;
	}

	.h-new-wrapper  .el-menu>.el-submenu:focus .el-submenu__title,
	.h-new-wrapper  .el-menu>.el-submenu:hover .el-submenu__title {
		color: #1AA8F0;
	}

	.h-new-wrapper  .el-menu>.el-submenu.is-active .el-submenu__title {
		color: #1AA8F0;
	}

	.header .btn-login {
		margin-left: 30px;
		font-size: 14px !important;
	}

	.el-dropdown-lang {
		display: flex;
		align-items: center;
		margin-left: 25px;
	}

	.el-dropdown-lang .iconfont {
		margin-right: 5px;
	}

	@media (min-width:1400px) {
		.el-menu-nav>li {
			margin-left: 10px !important
		}

		.top-search {
			margin-left: 60px;
		}
	}

	.el-drawer.ltr {
		background-color: #ffffff;
	}

	.h-wrapper .m-menu-nav {
		border: 0;
	}

	.el-drawer .logo {
		padding: 0 20px;
		margin-bottom: 20px;
	}

	.el-drawer__close-btn {
		outline: 0;
	}

	@media (max-width:1000px) {
		.el-menu-nav {
			display: none;
		}

		.menu-icon {
			display: block;
			margin-left: auto;
		}
	}

	.top-search {
		width: 350px;
	}

	.top-search .el-input__inner {
		height: 40px;
		border-radius: 20px;
		background: #FFFFFF;
		box-sizing: border-box;
		border: 1px solid #DFDFDF;
	}

	.btn-so {
		cursor: pointer;
		width: 40px;
		height: 40px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}

	.header .el-menu-item .el-icon-arrow-down {
		font-size: 14px;
	}

	/* 下拉 */
	.menu-dropdown-panel {
		position: fixed;
		top: 72px;
		left: 50;
		width: 100%;
		height: calc(100vh - 72px);
	}

	.menu-dropdown-panel::before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, .6);
	}

	.menu-dropdown-panel .container {
		position: relative;
		z-index: 33;
		background-color: #ffffff;
	}

	.active .dropdown-panel {
		animation: slide-up 0.3s;
		/* Apply animation */
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.3s ease;
	}

	.fade-enter,
	.fade-leave-to

	/* .fade-leave-active in <2.1.8 */
		{
		opacity: 0;
	}

	@keyframes slide-up {
		0% {
			transform: translateY(20px);
			opacity: 0;
		}

		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}

	.dropdown-aside {
		width: 446px;
		padding: 40px 22px;
		background: #F6F6F6;
	}

	.diy-select-photo {
		margin-bottom: 50px;
	}

	.diy-grid .diy-title {
		font-family: MiSans;
		font-size: 18px;
		font-weight: 600;
		line-height: 18px;
		letter-spacing: 0em;
		margin-bottom: 26px;
	}

	.diy-grid .diy-desc {
		font-size: 16px;
		font-weight: normal;
		line-height: normal;
		letter-spacing: 0em;
		color: #878787;
		margin-bottom: 36px;
	}

	.diy-grid .el-link.el-link--primary {
		color: #1AA8F0;
	}

	.dropdown-panel>.flex_bd {
		padding: 20px 40px;
	}

	.el-row .d-label {
		font-family: MiSans;
		font-size: 16px;
		font-weight: 600;
		line-height: 16px;
		letter-spacing: 0em;
		color: #BEBEBE;
		margin-bottom: 30px;
	}

	.pr-card {
		cursor: pointer;
		margin-bottom: 26px;
		display: flex;
		align-items: center;
	}

	.pr-card img {
		width: 76px;
		height: 76px;
		margin-right: 12px;
	}

	.pr-card h3 {
		font-size: 18px;
		margin-bottom: 10px;
	}
	.pr-card:hover h3{
		color: #1AA8F0;
	}

	.pr-card p {
		font-size: 16px;
		color: #878787;
	}

	.other-row {
		padding-top: 24px;
	}

	.drawer-panel {
		padding: 0 20px;
		padding-bottom: 30px;
		overflow: hidden;
		overflow-y: auto;
		height: calc(100vh - 90px);
	}

	.m-nav-title {
		font-size: 18px;
		font-weight: bold;
	}

	@media (max-width:750px) {
		.pr-card img {
			width: 70px;
			height: 70px;
			margin-right: 12px;
		}

		.pr-card h3 {
			font-size: 16px;
			line-height: 1;
		}

		.pr-card p {
			font-size: 14px;
			line-height: 1.4;
		}

		.el-row .d-label {
			margin-bottom: 10px;
		}

		.drawer-panel .el-row {
			padding-top: 20px;
		}
	}
</style>