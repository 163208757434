// import Vue from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store';
// import axios from 'axios'
// import ElementUI from 'element-ui';
// import BaiduMap from 'vue-baidu-map'
// import 'element-ui/lib/theme-chalk/index.css';
// import wow from 'wowjs';
// import 'wowjs/css/libs/animate.css';

// // swiper
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// Vue.prototype.$wow = wow
// Vue.use(BaiduMap, {
//   ak: 'CwmfazQzgtkmcrlml2KiQDo3r1FrUULi' // 替换为你在百度地图开发者平台申请的密钥
// })
// // import style
import 'swiper/swiper-bundle.css'

import "./assets/fonts/iconfont.css";
import "./assets/css/css.css";

// Vue.use(ElementUI);
// Vue.use(VueAwesomeSwiper)
// Vue.use(store)
// Vue.prototype.$axios = axios
// Vue.config.productionTip = false

// new Vue({
//   axios,
// 	store,
//   router,
//   render: h => h(App)
// }).$mount('#app')

// router.afterEach(() => {
// 	document.body.scrollTop = 0;
// 	document.documentElement.scrollTop = 0;
// })

// @ts-nocheck
import Vue from 'vue'
// @ts-ignore
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import store from './store';
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css';
import wow from 'wowjs';
import 'wowjs/css/libs/animate.css';

// swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.prototype.$wow = wow
// import style
// import 'swiper/swiper-bundle.css'

// import "./assets/fonts/iconfont.css";
// import "./assets/fonts/iconfont.js";
// import "./assets/css/css.css";


import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: 'CwmfazQzgtkmcrlml2KiQDo3r1FrUULi'  //  在此输入你自己的百度地图ak
})

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'


Vue.use(VideoPlayer)
Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper)
Vue.use(store)
Vue.prototype.$axios = axios
Vue.config.productionTip = false

new Vue({
	axios,
	store,
	router,
	render: h => h(App)
}).$mount('#app')